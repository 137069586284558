@import './variables';

.react-verification-input__container {
  width: 100%;
  margin: auto;
  height: 50px;
  gap: 0;
}

.react-verification-input__character {
  border: 1px solid $gray-300;
  border-radius: 4px;

  margin: 4px;
  text-align: center;
  padding: 8px;
  font-size: 18px;
  line-height: 27px;
  color: inherit;
}

.react-verification-input__character--selected {
  border: 1px solid $primary;
  outline: none;
}

.react-verification-input__character--inactive {
  background-color: $white;
}
