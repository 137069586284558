.progress-container {
  max-width: 528px;
  min-width: 322px;
  z-index: 1000;

  position: fixed;
  right: 8px;
  bottom: 16px;
  margin-left: 8px;

  &__consent-banner {
    bottom: 18%;
  }

  &__button {
    float: right;
    padding: 16px 20px;

    border-radius: 100px;
    min-width: fit-content;
    background-color: var(--primary);
    color: white;
  }

  &__toast {
    float: right;
    min-width: 100%;

    background-color: #fff;
    flex-basis: 0;

    &__header {
      padding: 18px;
      display: flex;
      justify-items: center;

      &__text {
        flex-grow: 1;
        margin: 0;
      }
    }

    &__body {
      padding: 18px;

      .text {
        flex-grow: 1;
      }
    }

    &__footer {
      display: flex;
      padding: 18px;

      &__form {
        flex-grow: 1;
      }
    }
  }
}

.progress-checkbox {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  vertical-align: middle;

  appearance: none;
  -webkit-appearance: none;
  outline: none;
  margin: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  &-unchecked {
    border: 1px solid #ddd;
    background-color: white;
  }

  &-checked {
    border: 1px solid var(--success);
    background-color: var(--success);
  }
}

.progress-checkbox--button {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  vertical-align: middle;
  padding: 2px;

  appearance: none;
  -webkit-appearance: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
}
