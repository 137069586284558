//
// Border color using validation
.was-validated {
  .react-select-container {
    &.react-select--valid .react-select__control {
      border-color: var(--success);
    }
    &.react-select--invalid .react-select__control {
      border-color: var(--danger);
    }
  }
}

//
// Displaying .invalid-feedback using validation
.was-validated
  .react-select-container.react-select--invalid
  ~ .invalid-feedback {
  display: block;
}
